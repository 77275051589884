// extracted by mini-css-extract-plugin
export var allSet = "event-registration-dialog-module--all-set--3dfba";
export var body = "event-registration-dialog-module--body--96372";
export var content = "event-registration-dialog-module--content--f4864";
export var eventDetails = "event-registration-dialog-module--event-details--751b6";
export var eventImage = "event-registration-dialog-module--event-image--8bd9c";
export var eventType = "event-registration-dialog-module--event-type--87000";
export var formContainer = "event-registration-dialog-module--form-container--0c391";
export var formContainerWaitlist = "event-registration-dialog-module--form-container-waitlist--f700c";
export var header = "event-registration-dialog-module--header--c7d55";
export var imageLg = "event-registration-dialog-module--image-lg--a7a31";
export var imageSm = "event-registration-dialog-module--image-sm--745f7";
export var submitted = "event-registration-dialog-module--submitted--75959";
export var success = "event-registration-dialog-module--success--7015a";
export var title = "event-registration-dialog-module--title--d18c5";
export var waitlistImage = "event-registration-dialog-module--waitlist-image--e1aab";