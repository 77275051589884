import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as styles from './event-timeline.module.scss';

UqEventTimeline.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      dateTime: PropTypes.string,
      notes: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
};

export function UqEventTimeline(props) {
  return (
    <ul className={styles.root}>
      {props.events.map((event, index) => (
        <li
          key={index}
          className={styles.event}
          data-date={event.dateTime}
        >
          <div className={styles.eventTitle}>
            {event.title}
          </div>
          <p className={classNames(styles.eventNotes)}>
            {event.notes}
          </p>
        </li>
      ))}
    </ul>
  );
}
