import React from 'react';
import PropTypes from 'prop-types';

import { Image } from 'react-bootstrap';

import * as styles from './featured-event.module.scss';

UqFeaturedEvent.propTypes = {
  dateTime: PropTypes.string,
  link: PropTypes.element,
  src: PropTypes.string,
  title: PropTypes.string,
  venue: PropTypes.string,
};

export function UqFeaturedEvent(props) {
  return (
    <div className={styles.root}>
      <Image
        className={styles.image}
        src={props.src}
      />
      <div className={styles.detailsContainer}>
        <h3>{props.title}</h3>
        <div className={styles.contentContainer}>
          <div className={styles.details}>
            <div className="body-1">
              {props.dateTime}
            </div>
            <div className="body-1">
              {props.venue}
            </div>
          </div>
          <div className={styles.link}>
            {props.link}
          </div>
        </div>
      </div>
    </div>
  );
}
