// extracted by mini-css-extract-plugin
export var carouselContainer = "why-community-module--carousel-container--3a5d0";
export var celebratingLeaders = "why-community-module--celebrating-leaders--a3e74";
export var hero = "why-community-module--hero--c1c87";
export var heroContainer = "why-community-module--hero-container--3ac87";
export var heroImageMobile = "why-community-module--hero-image-mobile--81e7d";
export var heroTitle = "why-community-module--hero-title--8bb12";
export var moreStories = "why-community-module--more-stories--77fea";
export var qualitySoiree = "why-community-module--quality-soiree--aa6b2";
export var qualitySoireeImage = "why-community-module--quality-soiree-image--010d5";
export var quickActions = "why-community-module--quick-actions--a1e7e";
export var subtitle = "why-community-module--subtitle--2f4f7";
export var testimonials = "why-community-module--testimonials--52c0c";
export var welcome = "why-community-module--welcome--e1d2d";
export var welcomeContainer = "why-community-module--welcome-container--855f3";
export var welcomeIntro = "why-community-module--welcome-intro--6d61a";