import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { UqLink } from '../navigation';
import LocationPin from './assets/location-pin.svg';

import * as styles from './event-address.module.scss';

UqEventAddress.propTypes = {
  address: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  venue: PropTypes.string.isRequired,
};

export function UqEventAddress(props) {
  return (
    <UqLink
      className={styles.root}
      to={props.url}
      external
      hideArrow
    >
      <span className={styles.address}>
        <LocationPin className={classNames(styles.locationPin, styles.desktop)} />
        <section>
          <span className={styles.location}>
            <LocationPin className={classNames(styles.locationPin, styles.mobile)} />
            <h4>{props.venue}</h4>
          </span>

          <span className="body-1">
            {props.address}
          </span>
        </section>
      </span>
    </UqLink>
  );
}
