import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { graphql } from 'gatsby';
import { Button } from 'react-bootstrap';

import { UqPage, UqAppBarV2, UqFooterV2, UqNavbarV2, UqLayoutV2, UqContainer, UqHeroV2, UqContainerV2, UqTextHighlight, UqRequestDemoBanner, UqTestimonialCard, UqFigure, UqQuickActionTile, UqFlexColumn, UqFlexGrid, UqTestimonialVideos } from '@uq-components';
import { figureRowShape, seoShape, testimonialShape, tileShape } from '../models';
import { WAITLIST_EVENT } from '../content/v2/events';
import { UqEventRegistrationDialog } from '../components/v2/events';

import * as styles from './why-community.module.scss';

const BASE_VIDEO_CONFIG = {
  autoplay: false,
  responsive: true,
  transparent: false,
};

UqWhyCommunityPage.propTypes = {
  data: PropTypes.shape({
    celebratingLeaders: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: figureRowShape,
        }),
      ),
    }),
    quickActions: PropTypes.shape({
      content: PropTypes.arrayOf(tileShape),
    }),
    seo: seoShape,
    testimonials: testimonialShape,
    videos: PropTypes.shape({
      content: PropTypes.arrayOf(testimonialShape),
    }),
  }),
}

export default function UqWhyCommunityPage(props) {
  const testimonials = props.data.testimonials;
  const [qualitySoiree, captureMarket, newsletter] = props.data.quickActions.content;
  const celebratingLeaders = props.data.celebratingLeaders.edges[0].node;

  const [isModalOpen, setModalOpen] = useState(false);

  const videos = [
    {
      ...props.data.videos.content[1],
      color: 'blue',
      playerProps: { ...BASE_VIDEO_CONFIG, url: props.data.videos.content[1].videoUrl },
    },
    {
      ...props.data.videos.content[0],
      color: 'purple',
      playerProps: { ...BASE_VIDEO_CONFIG, url: props.data.videos.content[0].videoUrl },
    },
    {
      ...props.data.videos.content[2],
      color: 'yellow',
      playerProps: { ...BASE_VIDEO_CONFIG, url: props.data.videos.content[2].videoUrl },
    },
  ];

  return (
    <UqPage
      config={{
        seo: {
          description: props.data.seo.description,
          title: props.data.seo.title,
        },
      }}
    >
      <UqLayoutV2>
        <UqAppBarV2>
          <UqNavbarV2 />
        </UqAppBarV2>

        <UqHeroV2>
          <UqContainer
            className={styles.heroContainer}
            fullWidth
          >
            <UqContainer>
              <section className={styles.hero}>
                <section className={styles.heroTitle}>
                  <h1>
                    <UqTextHighlight>
                      Meet the **Quality Community**
                    </UqTextHighlight>
                  </h1>
                  <span className="body-1">
                    Leaders delivering the quality users deserve
                  </span>
                </section>
                <div className={styles.heroImageMobile} />
              </section>
            </UqContainer>
          </UqContainer>
        </UqHeroV2>

        <section className={styles.welcomeContainer}>
          <UqContainer fullWidth>
            <section className={styles.welcome}>
              <UqContainer>
                <section className={styles.welcomeIntro}>
                  <h1>
                    <UqTextHighlight>
                      {testimonials.title}
                    </UqTextHighlight>
                  </h1>
                  <p className={classNames('body-1', styles.subtitle)}>
                    {testimonials.subtitle}
                  </p>
                </section>
                <section className={styles.testimonials}>
                  {testimonials.content.map((testimonial, index) => (
                    <UqTestimonialCard
                      key={index}
                      action={{
                        href: testimonial.link,
                        label: 'Read Story',
                        type: 'link',
                      }}
                      avatarSrc={testimonial.author.image.url}
                      logo={testimonial.customer.logo}
                      name={testimonial.author.name}
                      quote={testimonial.quote.quote}
                      role={testimonial.author.role}
                      variant="flipable"
                    />
                  ))}
                </section>
              </UqContainer>
              <section className={styles.moreStories}>
                <Button href="/customers">
                  Explore More Stories
                </Button>
              </section>
            </section>
          </UqContainer>
        </section>
        <UqContainer>
          <section className={styles.carouselContainer}>
            <UqTestimonialVideos videos={videos} />
          </section>
        </UqContainer>
        <UqContainer
          background="dark"
          fullWidth
        >
          <UqContainer variant="wide">
            <section className={styles.celebratingLeaders}>
              <UqFigure>
                <UqFigure.Content>
                  <UqFigure.Title fill>{celebratingLeaders.title}</UqFigure.Title>
                  <span>{celebratingLeaders.description.description}</span>

                  <UqFigure.Actions actions={celebratingLeaders.actions} />
                </UqFigure.Content>

                <UqFigure.Image src={celebratingLeaders.image.url} />
              </UqFigure>
            </section>
          </UqContainer>
        </UqContainer>
        <UqContainerV2>
          <section className={styles.quickActions}>
            <UqFlexGrid>
              <UqFlexColumn>
                <UqQuickActionTile
                  caption="Upcoming Dinners"
                  classes={{
                    image: styles.qualitySoireeImage,
                    root: styles.qualitySoiree,
                  }}
                  dark
                  renderAction={() => (
                    <Button
                      className="wide"
                      onClick={() => setModalOpen(true)}
                    >
                      Save Your Spot
                    </Button>
                  )}
                  size="lg"
                  src={qualitySoiree.image.url}
                  subtitle={qualitySoiree.desc.desc}
                  title={qualitySoiree.title}
                />
              </UqFlexColumn>
              <UqFlexColumn>
                <UqQuickActionTile
                  action={captureMarket.action}
                  caption="Annual Events"
                  dark
                  size="md"
                  src={captureMarket.image.url}
                  subtitle={captureMarket.desc.desc}
                  title={captureMarket.title}
                />
                <UqQuickActionTile
                  action={newsletter.action}
                  dark
                  size="sm"
                  title={newsletter.title}
                />
              </UqFlexColumn>
            </UqFlexGrid>
          </section>
        </UqContainerV2>
        <UqContainerV2>
          <UqRequestDemoBanner />
        </UqContainerV2>

        <UqFooterV2 />
      </UqLayoutV2>

      <UqEventRegistrationDialog
        open={isModalOpen}
        onClose={() => setModalOpen(false)}
        event={WAITLIST_EVENT}
      />
    </UqPage>
  );
}

export const pageQuery = graphql`
  query {
    celebratingLeaders:  allContentfulFigureRow(filter: {pageId: {eq: "why-community"}}) {
      edges {
        node {
          actions {
            href
            label
            type
          }
          description {
            description
          }
          image {
            url
          }
          title
        }
      }
    }
    quickActions: contentfulContentGroup(slug: {eq: "why-community-quick-action-tiles"}) {
      content {
        ... on ContentfulTile {
          action {
            href
            label
            type
          }
          desc {
            desc
          }
          image {
            url
          }
          title
        }
      }
    }
    seo:  contentfulSeo(pageId: {eq: "why-community"}) {
      description
      title
    }
    testimonials: contentfulContentGroup(slug: {eq: "why-community-testimonials"}) {
      content {
        ... on ContentfulTestimonial {
          author {
            company
            image {
              url
            }
            name
            role
          }
          customer {
            logo {
              svg {
                content: originalContent
              }
            }
            name
          }
          link
          quote {
            quote
          }
        }
      }
      subtitle
      title
    }
    videos: contentfulContentGroup(slug: {eq: "why-community-video-testimonials"}) {
      content {
        ... on ContentfulTestimonial {
           author {
            name
            role
            thumbnail {
              url
            }
          }
          customer {
            name
            spotlightLogo {
              svg {
                content
              }
            }
          }
          videoUrl
        }
      }
    }
  }
`;
