export const WAITLIST_EVENT = {
  // mimicing the structure from Contentful
  description: {
    description: 'Join our waitlist! <br> We will let you know as new events are added.',
  },
  eventTitle: 'Stay in touch',
  hubspotFormId: '75a446c5-6e91-48ee-817f-da810d86c07c',
  image: {
    url: '/images/v2/events/city-collage.png',
  },
};
