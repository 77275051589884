import React, { useRef, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import classNames from 'classnames';
import { Modal, Image, Button } from 'react-bootstrap';

import { eventShape } from '../../../models/event';
import { UqHubspotForm, UqExecutiveDinnerSeriesForm } from '../forms';
import { UqTypography } from '../typography';

import * as styles from './event-registration-dialog.module.scss';

UqEventRegistrationDialog.propTypes = {
  event: eventShape,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export function UqEventRegistrationDialog(props) {
  const { event } = props;

  const formRef = useRef(null);
  const [isSubmitted, setSubmitted] = useState(false);

  const navigateToHome = useCallback(() => {
    navigate('/');
  }, [navigate]);

  const closeModal = useCallback(() => {
    formRef.current?.reset();
    props.onClose();

    setSubmitted(false);
  }, [props.onClose, formRef.reset]);

  if (!props.open) {
    return null;
  }

  return (
    <Modal
      className="new-uq-theme"
      show={props.open}
      onHide={closeModal}
      centered
      keyboard
      size="xl"
    >
      <Modal.Header
        className={styles.header}
        closeButton
      />
      <Modal.Body className={styles.body}>
        <div className={classNames(styles.content, { [styles.submitted]: isSubmitted })}>
          <Image
            className={classNames(styles.imageSm, {
              [styles.eventImage]: event.city,
              [styles.waitlistImage]: !event.city,
            })}
            src={event.image.url}
          />
          <section
            className={classNames(styles.formContainer, {
              [styles.formContainerWaitlist]: !event.city,
              [styles.submitted]: isSubmitted,
            })}
          >
            {isSubmitted ? (
              <div className={styles.success}>
                <h2>🎉</h2>
                <h3 className={styles.allSet}>
                  You are all set!
                </h3>
                <Button onClick={navigateToHome}>
                  Go to unitQ Homepage
                </Button>
              </div>
            ) : (
              <>
                <h2 className={styles.title}>
                  {event.eventTitle || 'Save Your Spot'}
                </h2>
                <section className={styles.eventDetails}>
                  <div className={classNames('caption-1', styles.eventType)}>
                    {event.type}
                  </div>
                  {event.venue && (
                    <div className="body-1">
                      {event.venue} |
                    </div>
                  )}
                  {event.dateTime && (
                    <div className="body-2">
                      {event.dateTime}
                    </div>
                  )}
                  {!event.dateTime && !event.venue && (
                    <UqTypography variant="body-2">
                      {event.description.description}
                    </UqTypography>
                  )}
                </section>

                {event.hubspotFormId
                  ? (
                    <UqHubspotForm
                      id={event.hubspotFormId}
                      onSubmitted={() => setSubmitted(true)}
                    />
                  )
                  /* @todo replace with hubspot form */
                  : (
                    <UqExecutiveDinnerSeriesForm
                      ref={formRef}
                      city={event.city || null}
                      onSuccess={() => setSubmitted(true)}
                    />
                  )
                }
              </>
            )}
          </section>
          <Image
            className={classNames(styles.imageLg, {
              [styles.eventImage]: event.city,
              [styles.waitlistImage]: !event.city,
              [styles.submitted]: isSubmitted,
            })}
            src={event.image.url}
          />
        </div>
      </Modal.Body>
    </Modal >
  );
}
